// queries para Waterports
export function waterportsquery_DataByWaterPollutant(elasticAcronym, startTimestamp, endTimestamp) {
	return {
		query: {
			bool: {
				must: [
					{
						match_phrase: {
							WQpollutant: elasticAcronym
						}
					},
					{
						range: {
							date_timestamp: {
								gte: startTimestamp,
								lte: endTimestamp
							}
						}
					}
				]
			}
		},
		size: '10000',
		sort: [
			{
				date_timestamp: {
					order: 'asc'
				}
			}
		]
	};
}

export function waterportsquery_LastDataByWaterPollutant(elasticAcronym) {
	return {
		query: {
			bool: {
				must: [
					{
						match_phrase: {
							WQpollutant: elasticAcronym
						}
					}
				]
			}
		},
		size: '1',
		sort: [
			{
				date_timestamp: {
					order: 'desc'
				}
			}
		]
	};
}

export function waterportsquery_FirstDataByWaterPollutant(elasticAcronym) {
	return {
		query: {
			bool: {
				must: [
					{
						match_phrase: {
							WQpollutant: elasticAcronym
						}
					}
				]
			}
		},
		size: '1',
		sort: [
			{
				date_timestamp: {
					order: 'asc'
				}
			}
		]
	};
}
